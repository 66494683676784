<template>
  <div class="mb-2">
    <b-input-group :prepend="label || $t('artistsManagement.aboutEN')">
      <b-textarea
        v-model="value"
        @change="emit"
        @keypress="disableEnter"
        @input="disableEnter"
        :maxlength="maxlength"
        type="textarea"
        :placeholder="placeholder"
        :required="required"
      />
    </b-input-group>
    <small>{{ `${value ? value.length : 0}/${maxlength}` }}</small>
  </div>
</template>

<script>
export default {
  props: ['value', 'maxlength', 'label', 'placeholder', 'enableEnter'],

  methods: {
    disableEnter(e) {
      if (this.enableEnter) return;
      if (e.key === 'Enter') e.preventDefault();
      const { value = '' } = this || {};
      const spaceRemoved = (value || '').replace(/\n/g, '');

      if (value !== spaceRemoved) {
        this.value = spaceRemoved;
      }
    },

    emit() {
      this.$emit('input', this.value);
    },
  },

  computed: {
    required() {
      return this.$attrs.required || false;
    },
  },
};
</script>

<style lang="sass" scoped>
small
  text-align: right
  display: block
</style>
