<template>
  <div>
    <b-form-file
      accept="image/*"
      @change="imageSelected"
      v-model="value.file"
      :state="Boolean(value.file)"
      :placeholder="$t('buttons.selectFile')"
      :drop-placeholder="$t('buttons.dropFile')"
      ref="fileRef"
    ></b-form-file>
    <label
      ><small>
        {{ $t("inputs.resolutionInstruction") }}
        {{ minWidth }}x{{ minHeight }}
      </small>
    </label>

   <b-modal ref="modal-crop" size="lg" :title="$t('general.cropTitle')"
    no-close-on-esc
    no-close-on-backdrop
    hide-header-close>
      <div class="d-block text-center">
        <p>{{ $t("general.howToCrop") }}</p>
        <v-clipper-basic
          ref="clipper"
          :ratio="minWidth / minHeight"
          :src="base64Image"
          :minWidth="minWidthP"
          :initWidth="minWidthP"
        ></v-clipper-basic>
      </div>
      <template v-slot:modal-footer>
        <div class="w-100 text-right footer-buttons">
          <b-button
            size="md"
            class="mr-3"
            variant="outline-primary"
            @click="cancelCrop()"
            >{{ $t("buttons.cancel") }}</b-button
          >
          <b-button size="md" variant="primary" @click="okCrop()">{{
            $t("buttons.ok")
          }}</b-button>
        </div>
      </template>
    </b-modal>
  </div>
</template>

<script>
import Toast from '../../assets/js/toast';

export default {
  props: ['value', 'minWidth', 'minHeight'],

  data() {
    return {
      selectedTag: undefined,
      data: [],
      base64Image: null,
      minWidthP: 100,
    };
  },

  methods: {
    changeFile() {
      try {
        this.$refs.fileRef.$el.childNodes[0].click();
      } catch (e) {
        //
      }
    },

    cancelCrop() {
      this.$emit('input', {
        preview: null,
        file: null,
        blob: null,
      });
      this.$refs['modal-crop'].hide();
    },

    okCrop() {
      // call component's clip method
      const canvas = this.$refs.clipper.clip({ maxWPixel: this.minWidth * 2 });
      const model = {
        preview: canvas.toDataURL('image/jpeg', 1),
        file: this.value.file,
        blob: null,
      };
      model.blob = this.b64toBlob(model.preview);
      this.$emit('input', model);
      this.$refs['modal-crop'].hide();
      this.base64Image = null;
    },

    b64toBlob(b64) {
      // Split the base64 string in data and contentType
      const block = b64.split(';');
      // Get the content type of the image
      const contentType = block[0].split(':')[1]; // In this case "image/gif"
      // get the real base64 content of the file
      const b64Data = block[1].split(',')[1]; // In this case "R0lGODlhPQBEAPeoAJosM...."
      const sliceSize = 512;

      const byteCharacters = atob(b64Data);
      const byteArrays = [];

      for (
        let offset = 0;
        offset < byteCharacters.length;
        offset += sliceSize
      ) {
        const slice = byteCharacters.slice(offset, offset + sliceSize);

        const byteNumbers = new Array(slice.length);
        for (let i = 0; i < slice.length; i += 1) {
          byteNumbers[i] = slice.charCodeAt(i);
        }

        const byteArray = new Uint8Array(byteNumbers);

        byteArrays.push(byteArray);
      }

      const blob = new Blob(byteArrays, { type: contentType || '' });
      return blob;
    },

    imageSelected(e) {
      try {
        const file = e.target.files[0];
        const base64Image = URL.createObjectURL(file);
        // Initiate the JavaScript Image object.
        const image = new Image();

        // Set the Base64 string return from FileReader as source.
        image.src = base64Image;
        // Validate the File Height and Width.
        image.onload = this.imageLoaded;
      } catch (err) {
        //
      }
    },

    imageLoaded(e) {
      const { width, height } = e.target;
      if (height < this.minHeight || width < this.minWidth) {
        const model = { ...this.value };
        model.file = null;
        this.$emit('input', model);
        Toast.error(this, 'messages.imageTooSmall');
        return false;
      }
      this.minWidthP = (this.minWidth / width) * 100;
      this.base64Image = e.target.src;
      this.$refs['modal-crop'].show();
      return true;
    },
  },

  computed: { },
};
</script>

<style lang="sass" scoped>
</style>
